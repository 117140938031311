/* globals nx, s */

export function doAdobeCategory(category) {
    // evar112 - set in the backend on add to cart
    s.events = 'event158';
    s.linkTrackEvents = 'event158';
    s.linkTrackVars = 'prop16,prop25,prop26,prop27,prop58,eVar25,eVar26,eVar27,eVar58';
    s.prop16 = category;
    s.tl(true, 'o', 'masthead navigation click');
}

export function doAdobeL1Click(category) {
    // event159, eVar113 as the label of the category
    s.events = 'event159';
    s.linkTrackEvents = 'event159';
    s.linkTrackVars = 'eVar113';
    s.eVar113 = category;
    s.tl(true, 'o', 'masthead navigation level 1 click');
}

