import { get } from 'lodash';
/********************************
 * helpers
 *******************************/
const tracking = 'tracking';
const cart = 'cart';
const inCartKey = 'in_cart';
const stripClassName = '.promo_container';
const excludeAttribute = 'data-exclude-incart';
const itemKey = 'data-itemkey';
const containerClassName = 'ps_ic_container';

const getContent = count =>
  `<div class="promo_strip ps_ic">${count} in cart</div>`;

const updateStrip = inCart => strip => {
  maybeUpdatePdpp();
  const exclude = strip.getAttribute(excludeAttribute);
  const key = strip.getAttribute(itemKey);
  const count = inCart[key];

  if (exclude || !count) {
    if(strip.classList.contains(containerClassName)) {
      strip.classList.remove(containerClassName);
      strip.innerHTML = '';
    }
    return;
  }

  strip.innerHTML = getContent(count);
  strip.classList.add(containerClassName);
};

/********************************
 * maybeUpdatePdpp()
 *******************************/
function maybeUpdatePdpp() {
  if (!nxDatalayer?.event?.blowup?.prodcode) return; // are we on pdp?
  const prodcode = nxDatalayer.event.blowup.prodcode;
  if (!nxDatalayer?.tracking?.cart?.in_cart || !nxDatalayer.tracking.cart.in_cart[prodcode]) return; // is item in cart?
  const quantity = nxDatalayer?.tracking.cart.in_cart[prodcode];
  const holder = document.querySelector('.pdpp_actions__purchase_label');
  const html = `<div class="pdpp_actions__purchase_label--in_cart">
    <i class="fa-solid fa-circle-info"></i> You already have ${quantity} in cart
  </div>`;
  // either replace existing or insert new
  if (holder) {
    holder.innerHTML = html;
  } else {
    const newHtml = `<div class="pdpp_actions__purchase_label">${html}</div>`;
    const atc = document.querySelector('.pdpp_atc');
    if (atc) {
      atc.insertAdjacentHTML('beforebegin', newHtml);
    }
  }
}

/********************************
 * updateInCartStrip()
 *******************************/
const updateInCartStrip = () => {
  if (typeof nxDatalayer === 'undefined') {
    return;
  }
  const inCart = get(nxDatalayer, [tracking, cart, inCartKey]);
  const strips = document.querySelectorAll(stripClassName);

  if (inCart === undefined) {
    return;
  }

  strips.forEach(updateStrip(inCart));
};

export default updateInCartStrip;
/********************************
 * export for testing
 *******************************/
export {
  tracking,
  cart,
  inCartKey,
  stripClassName,
  excludeAttribute,
  itemKey,
  containerClassName,
  getContent,
  updateStrip
};
