const minQty = 1;
const maxQty = 999999;

const changeBtnState = (el, min) => {
    const decBtn = el.parentNode.querySelector('.itemcard__qty_less');
    const incBtn = el.parentNode.querySelector('.itemcard__qty_more');
    const input = el.parentNode.querySelector('input');

    const minimumQty = min !== undefined ? min : minQty;
    if(input.value <= minimumQty) {
        decBtn.disabled = true;
    } else {
        decBtn.disabled = false;
    }

    if(input.value >= maxQty) {
        incBtn.disabled = true;
    } else {
        incBtn.disabled = false;
    }
}
export const increaseQty = (btn, min) => {
    const input = btn.parentNode.querySelector('input');
    let v = 1 + parseInt(input.value, 10);
    if(v > maxQty) v = maxQty;
    input.value = v;
    changeBtnState(btn, min);
};
export const decreaseQty = (btn, min) => {
    const input = btn.parentNode.querySelector('input');
    let v = -1 + parseInt(input.value, 10);
    const minimumQty = min !== undefined ? min : minQty;
    if(v < minimumQty) v = minimumQty;
    input.value = v;
    changeBtnState(btn, min);
};
export const focusItem = (el, focusMe) => {
    if(focusMe) {
        el.parentNode.classList.add('itemcard__qty-ctrl--focused');
        el.select();
    } else {
        el.parentNode.classList.remove('itemcard__qty-ctrl--focused');
    }
};
export const changeItem = (btn, min) => {
    const input = btn.parentNode.querySelector('input');
    const minimumQty = min !== undefined ? min : minQty;
    if(input.value < minimumQty) input.value = minimumQty;
    if(input.value > maxQty) input.value = maxQty;
    changeBtnState(btn, min);
};
