/* globals s, nx */
var megaNav = function megaNav() {
  this.initialize.apply(this, arguments);
};

megaNav.prototype.initialize = function initialize() {
  var obj = this;

  if (!nx.ute.readDatalayer(['global', 'streams', 'meganav'])) {
    return;
  }

  obj.active = false;

  // store frequently used menu parts
  this.masthead_wrapper = document.querySelector('.masthead-wrapper');
  this.masthead_l1_holder = document.querySelector('.masthead-l1-holder');
  this.l1_categories = document.getElementById('l1_categories');
  this.mega_bg = document.querySelector('.mega-bg');
  this.body = document.getElementsByTagName('body')[0];
  this.mob_nav_button = document.querySelector('.mob-nav-button');
  this.nav_container = document.querySelector('#nav_container');

  this.masthead_wrapper.classList.add('mega-nav');

  obj.meganav = document.getElementById('meganav');
  obj.level2_holder = document.getElementById('level2_holder');
  obj.level3_holder = document.getElementById('level3_holder');

  obj.attachSpecials();
  obj.attachShopProducts();
  obj.initialize_bg();
  obj.initializeElements(document);
  obj.setupLevel2();
  obj.catchEscape();

  // close small menus or masthead if user clicks out of them
  this.smallMenusOpen = false;
  document.addEventListener('click', function(ev) {
    if (obj.smallMenusOpen && !ev.target.closest('#specials_holder, #shop_by_holder')) {
      obj.closeSmallMenus();
    }
    // body has no_scroll class if mega-nav is open
    if (document.body.classList.contains('no_scroll') && !ev.target.closest('#meganav', 'masthead-l1-holder')) {
      obj.close1320();
    }
  });

  this.mob_nav_button.addEventListener('click', function(evt) {
    obj.mobileMenu(this, evt);
  });

  nx.ute.resizeManager.register(obj, 2);
};

megaNav.prototype.catchEscape = function catchEscape() {
  var obj = this;

  document.body.addEventListener('keyup', function(evt) {
    if (evt.key == 'Escape') { // Close all menus
      obj.closeSmallMenus();
      obj.closeLeft();
      obj.close1320();
    }
  });
};

megaNav.prototype.catchEnter = function catchEnter(evt) {
  var obj = this;
  var the_li = evt.target;

  if (the_li.tagName === 'LI' && evt.key == 'Enter') {
    the_li.children[0].click(); // Click on the Anchor tag
  }
};

megaNav.prototype.attachSpecials = function attachSpecials() {
  fetch('/_menu/special_offers', { method: 'GET' })
    .then(response => response.text())
    .then(data => {
        const the_menu = document.getElementById('specials_menu');
        if (the_menu) the_menu.innerHTML = data;
    });

    return;
};

megaNav.prototype.attachShopProducts = function attachShopProducts() {
  const shop_btn = document.getElementById('shop_products');
  var obj = this;

  if (shop_btn) {
    shop_btn.addEventListener('click', el => {
      this.masthead_l1_holder.classList.remove('hide_categories');
      this.masthead_l1_holder.classList.add('expanded-nav');
      this.l1_categories.classList.add('expanded-nav');
      this.l1_categories.querySelector('ul > li:first-child a').focus();
      this.mega_bg.classList.add('expanded');
      this.body.classList.add('no_small_scroll');
      this.closeSmallMenus();
    });

    document.querySelector('#l1_header button').addEventListener('click', el => {
      const the_nav = this.l1_categories;

      the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
        l1.classList.remove('nav-open');
      });
      obj.initialiseLazyLoaders(the_nav);

      /* TODO: Put this in a method that other people can call */
      this.mob_nav_button.classList.remove('mob-nav-button-active');
      this.masthead_wrapper.classList.remove('expanded');
      this.masthead_wrapper.classList.remove('sub-expanded');
      this.mega_bg.classList.remove('expanded-nav');
      this.mega_bg.classList.remove('expanded');
      this.body.classList.remove('no_scroll');
      this.body.classList.remove('no_small_scroll');
      this.l1_categories.classList.remove('expanded-nav');
      this.masthead_l1_holder.classList.add('hide_categories');
      this.masthead_l1_holder.classList.remove('expanded-nav');
      obj.level2_holder.classList.add('hide_categories');
      obj.level3_holder.classList.add('hide_categories');
    });
  }

  return;
};

megaNav.prototype.initialize_bg = function initialize_bg() {
  var obj = this;

  document.querySelectorAll('.mega-back, .mega-bg').forEach(overlay => {
    overlay.addEventListener('click', el => {
      obj.close1320();
    });
  });

  return;
};

megaNav.prototype.initializeElements = function initializeElements(doc) {
  var obj = this;
  var l, mb, menu_dropdown_id, mb_dropdown;

  /* Load visible menu */
  const loaders = doc.querySelectorAll('.nav-loader');
  for (let i = 0; i < loaders.length; i++) {
    l = loaders[i];
    obj.menuLoader(l, doc);
  }

  obj.initialiseLazyLoaders(doc);

  const menu_buttons = doc.querySelectorAll('.nav-button');
  for (let i = 0; i < menu_buttons.length; i++) {
    mb = menu_buttons[i];
    menu_dropdown_id = mb.getAttribute('aria-controls');
    mb_dropdown = document.getElementById(menu_dropdown_id);

    mb.registry ??= {};
    mb.registry.click ??= [];
    mb.registry.click.forEach(h => mb.removeEventListener('click', h));
    mb.registry.click = [];

    let mbClickHandler = obj.toggleMenu.bind(obj, mb, mb_dropdown);
    mb.registry.click.push(mbClickHandler);
    mb.addEventListener('click', mbClickHandler);
  }
};

megaNav.prototype.initialiseLazyLoaders = function initialiseLazyLoaders(doc) {
  var obj = this;
  var ll;

  const lazyloaders = doc.querySelectorAll('.nav-lazy-loader:not(.nav-open)');

  for (let i = 0; i < lazyloaders.length; i++) {
    ll = lazyloaders[i];

    ll.registry ??= {};
    ll.registry.click ??= [];
    ll.registry.click.forEach(h => ll.removeEventListener('click', h));
    ll.registry.click = [];

    let llClickHandler = obj.lazyExpand.bind(obj, ll, doc);
    ll.registry.click.push(llClickHandler);
    ll.addEventListener('click', llClickHandler);

    ll.registry.keydown ??= [];
    ll.registry.keydown.forEach(h => ll.removeEventListener('keydown', h));
    ll.registry.keydown = [];

    let llKeyHandler = obj.lazyExpandKey.bind(obj, ll, doc);
    ll.registry.keydown.push(llKeyHandler);
    ll.addEventListener('keydown', llKeyHandler);
  }
};

megaNav.prototype.lazyExpandKey = function lazyExpandKey(umenu, doc, evt) {
  var obj = this;

  if (evt) {
    if (evt.key == 'Enter') {
      obj.lazyExpand(umenu, doc);
    }
  }
};

megaNav.prototype.menuLoader = function menuLoader(l, doc) {
  var obj = this;
  const endpoint = l.getAttribute('data-url');
  const is_l3 = endpoint.match(/level3/);

  l.innerHTML = '<img src="/media/images/new/ajax-mini.gif" />';

  fetch(endpoint, { method: 'GET' })
    .then(response => response.text())
    .then(data => {
      /* On success or failure, we don't want to retry the
       * loader. Either will cause a request loop. */
      l.innerHTML = data;
      l.classList.remove('nav-loader');

      const order_rules = document.getElementsByClassName('opi-tile');
      if (order_rules.length) {
        const mega_top = obj.meganav.getBoundingClientRect().top;
        const mast_top = document.getElementsByClassName('scrolling-masthead')[0].getBoundingClientRect().top;
        order_rules[0].style.top = (mega_top - mast_top - 2) + 'px';
      }

      obj.initializeElements(doc);
    });
};

megaNav.prototype.cleanLevel3 = function cleanLevel3() {
  const l3 = document.getElementById("level3_container");

  if (l3) {
    l3.innerHTML = '<div id="l3_left">' +
      '<div></div></div>' +
      '<div id="l3_right"><div></div></div>';
  }
};

megaNav.prototype.cleanLevel2 = function cleanLevel2() {
  var obj = this;
  const level2 = document.querySelector('#level2_holder ul');

  if (level2) {
    level2.querySelectorAll('li')
      .forEach(l2 => {
        l2.removeEventListener('click', obj.expandToggle); // free memory
      }
    );
  }
};

megaNav.prototype.toggleMenu = function toggleMenu(button, dropdown, ev) {
  if (ev) {
    var element = ev.target;
    if (element.tagName == 'A') return;
    if (element.tagName == 'INPUT') return;
    ev.stopPropagation();
    ev.preventDefault();
  }
  const wasOpen = dropdown.classList.contains('expanded');
  // close small menus
  this.closeSmallMenus();
  // close mega-nav
  this.closeLeft();
  // maybe open this one
  if (!wasOpen) {
    const complimentryLookup = {
      // helps get a handle on the button for the other resolution
      'specials_heading': 'specials_heading_1320',
      'more_shop_heading': 'shop_heading',
      'specials_heading_1320': 'specials_heading',
      'shop_heading': 'more_shop_heading'
    }

    const icon = button.querySelector('.fa');
    icon.className = 'fa fa-chevron-up';
    button.setAttribute('aria-expanded', true);
    const complimentryButton = document.getElementById(complimentryLookup[button.id]);
    const complimentaryIcon = complimentryButton.querySelector('.fa');
    complimentaryIcon.className = 'fa fa-chevron-up';
    complimentryButton.setAttribute('aria-expanded', true);
    this.expandMenu(dropdown, button);
    this.smallMenusOpen = true;
  }
};

megaNav.prototype.closeSmallMenus = function closeSmallMenus() {
  // these buttnos are repeated at 996 and 1320 resolutions
  const buttonIds = [ 'specials_heading', 'shop_heading'];
  const otherButtonIds = ['specials_heading_1320', 'more_shop_heading'];
  const navIds = ['specials_menu', 'shop_by_menu'];
  for (let i = 0; i < buttonIds.length; i++) {
    const button = document.getElementById(buttonIds[i]);
    if (!button) continue;
    const otherButton = document.getElementById(otherButtonIds[i]);
    const icon = button.querySelector('.fa');
    const otherIcon = otherButton.querySelector('.fa');
    const dropdown = document.getElementById(navIds[i]);
    if (dropdown.classList.contains('expanded')) {
      icon.className = 'fa fa-chevron-down';
      otherIcon.className = 'fa fa-chevron-down';
      button.setAttribute('aria-expanded', false);
      otherButton.setAttribute('aria-expanded', false);
      this.collapseMenu(dropdown, button);
    }
  }
  this.smallMenusOpen = false;
}

megaNav.prototype.mobileMenu = function mobileMenu(button, ev) {
  var icon, dls;
  const really_mobile = (window.outerWidth < 481); // phone-lg breakpoint

  if (ev) {
    ev.stopPropagation();
    ev.preventDefault();
  }

  const l1_holder = this.masthead_l1_holder;
  const l1_cat = this.l1_categories;
  const wrapper = this.masthead_wrapper;
  const shop_by_menu = document.getElementById('shop_by_menu');

  if (button.classList.contains('mob-nav-button-active')) {
    button.classList.remove('mob-nav-button-active');
    document.getElementById('masthead_mobile').classList.remove('fixed');
    this.body.classList.remove('no_small_scroll');
    this.body.classList.remove('no_scroll');

    l1_holder.classList.add('hide_categories');
    l1_holder.classList.remove('expanded-nav');
    l1_cat.classList.remove('expanded-nav');
    l1_cat.querySelectorAll('.nav-open').forEach(function (l1) {
      l1.classList.remove('nav-open');
    });
    wrapper.classList.remove('expanded');
    wrapper.classList.remove('sub-expanded');
    this.mega_bg.classList.remove('expanded');
    this.meganav.classList.add('hide_categories');
  } else {
    button.classList.add('mob-nav-button-active');
    document.getElementById('masthead_mobile').classList.add('fixed');
    this.body.classList.add('no_small_scroll');

    l1_holder.classList.remove('hide_categories');
    l1_holder.classList.add('expanded-nav');
    l1_cat.classList.add('expanded-nav');
    wrapper.classList.add('expanded');
    shop_by_menu.classList.add('expanded');
    this.mega_bg.classList.add('expanded');
  }
};

megaNav.prototype.closeMenu = function closeMenu(button, dropdown, ev) {
  if (
    dropdown.classList.contains('menu-open') &&
    !dropdown.contains(ev.target)
  ) {
    this.collapseMenu(dropdown, button);
  }
};

megaNav.prototype.collapseMenu = function collapseMenu(dropdown, button) {
  dropdown.classList.remove('expanded');

  setTimeout(function() {
    dropdown.focus();
  }, 501); // CSS animation needs to finish
};

megaNav.prototype.expandMenu = function expandMenu(dropdown, button) {
  dropdown.classList.add('expanded');

  const mobile_expanded = document.querySelector('.mega-nav.expanded');
  if (mobile_expanded) {
    setTimeout(function() {
      const dummy = dropdown.getElementsByTagName('a');
    }, 501); // CSS animation needs to finish
    return;
  }

  setTimeout(function() {
    dropdown.getElementsByTagName('a')[0].focus();
  }, 501); // CSS animation needs to finish
};

// this closes the mega menu 1320px version
megaNav.prototype.close1320 = function close1320() {
  this.cleanLevel3();
  this.cleanLevel2();

  /* Optimise me */
  document.querySelectorAll('.nav-open')
    .forEach(d => {
      d.classList.remove('nav-open');
    }
  );

  this.mob_nav_button.classList.remove('mob-nav-button-active');

  document.querySelectorAll('#level2_holder, #nav_container, .masthead-l1-holder, .mega-bg').forEach(d => {
    d.classList.remove('expanded-nav');
  });

  this.masthead_wrapper.classList.remove('expanded');
  this.mega_bg.classList.remove('expanded');
  this.meganav.classList.add('hide_categories');
  this.level3_holder.classList.add('hide_categories');
  this.body.classList.remove('no_scroll');
  this.body.classList.remove('no_small_scroll');

  this.initializeElements(document);
}

megaNav.prototype.closeLeft = function closeLeft() {
  // this closes the mega menu variations that come from the left
  // i.e. all except 1320 version
  document.querySelector('.mega-back').classList.remove('expanded-nav');
  this.mob_nav_button.classList.remove('mob-nav-button-active');
  this.mega_bg.classList.remove('expanded-nav');
  this.mega_bg.classList.remove('expanded');
  this.l1_categories.classList.remove('expanded-nav');
  this.masthead_l1_holder.classList.remove('expanded-nav');
  this.nav_container.classList.remove('expanded-nav');

  this.meganav.classList.add('hide_categories');
  this.level2_holder.classList.add('hide_categories');
  this.level3_holder.classList.add('hide_categories');
  const the_nav = this.l1_categories;

  the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
    l1.classList.remove('nav-open');
  });
  this.masthead_wrapper.classList.remove('sub-expanded');
  this.masthead_wrapper.classList.remove('expanded');

  this.body.classList.remove('no_scroll');
  this.body.classList.remove('no_small_scroll');
  this.initialiseLazyLoaders(the_nav);
};

megaNav.prototype.lazyExpand = function lazyExpand(umenu, doc, ev) {
  /* LazyExpand - AJAX call to get the next level of categories */
  var element, contents, contents_id;
  var obj = this;
  this.closeSmallMenus();

  // extra instrumentation if L1 link is clicked
  if (ev && ev.target.closest('#l1_categories')) {
    nx.navigation.doAdobeL1Click(ev.target.innerText);
  }

  if (ev) {
    element = ev.target;
    if (element.tagName == 'A' && !element.classList.contains('nav-expand-href')) {
      nx.navigation.doAdobeCategory(element.getAttribute('data-category'));
      return;
    }
    ev.stopPropagation();
    ev.preventDefault();
  }

  if (obj.active) {
    return;
  }

  this.masthead_wrapper.classList.add('sub-expanded');

  const endpoint = umenu.getAttribute('data-url');
  const is_l3 = endpoint.match(/level3/);
  let output;

  if (is_l3) {
    output = '#level3_holder';

    contents = obj.level3_holder;
    if (!contents) return;

    obj.active = true;
    document.querySelector('#l3_left > div').classList.add('clean');
    document.querySelector('#l3_right').classList.add('clean');

    obj.level2_holder.classList.add('hide_categories');
    obj.level3_holder.classList.remove('hide_categories');
  } else {
    output = '#level2_holder > nav > .categories_container';

    contents = document.querySelector(output);
    if (!contents) return;

    obj.active = true;
    obj.level2_holder.classList.add('clean');
    const left_categories = document.querySelector('#l3_left > div');
    if (left_categories) {
      left_categories.classList.add('clean');
      document.querySelector('#l3_right').classList.add('clean');
    }

    this.masthead_l1_holder.classList.add('hide_categories');
    this.l1_categories.classList.remove('expanded-nav');
    obj.meganav.classList.remove('hide_categories');
    obj.level2_holder.classList.remove('hide_categories');

    document.querySelectorAll('.mega-back, #nav_holder > div:first-child, #nav_holder > div:first-child > div:first-child')
      .forEach(d => {
        d.classList.add('expanded-nav');
      }
    );
  }

  obj.expandToggle(umenu);

  contents.querySelectorAll('li')
    .forEach(l2 => {
      l2.removeEventListener('click', obj.expandToggle); // free memory
    }
  );

  if (is_l3) {
    contents.innerHTML =
      '<div id="level3_container">' +
      '<div id="l3_left">' +
      '<h2><ul><li><i class="fa fa-spinner fa-spin"></i></li></ul></h2>' +
      '</div>' +
      '<div id="l3_right"></div>' +
      '</div>';
  } else {
    const spinr = contents.querySelector('h2');

    if (spinr) {
      spinr.innerHTML = '<i class="fa fa-spinner fa-spin"></i>';
    } else {
      contents.innerHTML = '<h2><i class="fa fa-spinner fa-spin"></i></h2>';
    }
  }

  fetch(endpoint, { method: 'GET' })
    .then(response => (response.status == '200' ? response.text() : ''))
    .then(data => {
      var base_element;
      let item_cnt = 0;
      let parts = [ ];

      if (is_l3) {
        if ((/<p id="NO_LEVEL_C"/s).test(data)) { // Missing level C categories, so bounce
          const link = umenu.querySelector('a');
          if (link) {
            nx.navigation.doAdobeCategory(link.getAttribute('data-category'));
            window.location = link.href;
          }
          return;
        }

        contents.innerHTML = data;

        item_cnt = data.match(/<li\s+data-url/gs)?.length || 0;
        obj.fixCategoryHeight(item_cnt);
        base_element = contents;
        document.querySelector('#l3_left > div').classList.remove('clean');
        document.querySelector('#l3_right').classList.remove('clean');

        obj.toggleLevel3Header();
      } else {
        if ((/<p id="NO_LEVEL_B"/s).test(data)) { // Missing level B categories, so bounce
          window.location = umenu.querySelector('a').href;
          return;
        }

        base_element = contents.parentNode;

        if ((/<p id="NO_LEVEL_C"/s).test(data)) { // Missing level C categories, so bounce
            window.location = umenu.querySelector('a').href;
            return;
        } else if ((/<level3\s*>.+<\/level3>/s).test(data)) {
          parts = (/^(.+)<level3\s*>(.+)<\/level3>/s).exec(data);
        } else {
            parts[1] = data;
        }

        contents.innerHTML = parts[1];
        obj.level2_holder.classList.remove('clean');


        if (parts[2]) {
          obj.level3_holder.innerHTML = parts[2];
          document.querySelector('#l3_left > div').classList.remove('clean');
          document.querySelector('#l3_right').classList.remove('clean');

          obj.toggleLevel3Header();

          item_cnt = parts[2].match(/<li\s+data-url/gs).length;
          obj.fixCategoryHeight(item_cnt);
        }
      }

      document.querySelectorAll('.mega-back, #nav_holder > div:first-child, #nav_holder > div:first-child > nav ul, .mega-bg')
        .forEach(d => {
          d.classList.add('expanded-nav');
        }
      );
      this.body.classList.add('no_scroll');

      if (is_l3) {
        document.getElementById('go_level2').addEventListener('click', el => {
          const the_nav = obj.level2_holder;

          the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
            l1.classList.remove('nav-open');
          });
          obj.initialiseLazyLoaders(the_nav);

          obj.level2_holder.classList.remove('hide_categories');
          obj.level3_holder.classList.add('hide_categories');
          let first_choice = the_nav.querySelector('li:first-child a');
          first_choice.focus();
        });
        obj.level3_holder.querySelector('.fa-close').addEventListener('click', el => {
          this.closeLeft();
        });
      }

      obj.initializeElements(base_element, is_l3);

      setTimeout(function() {
        let first_choice = contents.querySelector('li:first-child a');

        if (!first_choice) {
          first_choice = contents.nextElementSibling
                            ? contents.nextElementSibling.querySelector('li:first-child a')
                            : contents;
        }
        first_choice.focus();
        // make sure the first loaded list item is show as selected,
        // unless it's a level_3
        if (!first_choice.closest('level3_container')) {
          const li = first_choice.closest('li');
          if (li) li.classList.add('nav-open');
        }
      }, 501); // Give time for CSS animation to finish

      umenu.registry ??= {};
      umenu.registry.click ??= [];
      umenu.registry.click.forEach(h => umenu.removeEventListener('click', h));
      umenu.registry.click = [];

      let umenuClickHandler = obj.expandToggle.bind(obj, umenu);
      umenu.registry.click.push(umenuClickHandler);
      umenu.addEventListener('click', umenuClickHandler);

      document.querySelectorAll('#level2_holder ul, #level3_container').forEach(el => {
      });

      obj.active = false; // Allow menu to be called again
    });
};

megaNav.prototype.fixCategoryHeight = function fixCategoryHeight(item_cnt) {
  const the_area = document.getElementById('l3_left');
  if (!the_area) {
    return;
  }
  const scroll_height = the_area.clientHeight - 100;
  const _ITEM_HEIGHT_ = 36;

  const space_needed = Math.ceil(item_cnt / 2) * _ITEM_HEIGHT_;
  const scroll_area = the_area.querySelector('.categories_container ul');
  if (!scroll_area) return;
  if (space_needed > scroll_height) {
    scroll_area.style.setProperty('max-height', space_needed + 'px');
  } else {
    scroll_area.style.setProperty('max-height', '100%');
  }
};

megaNav.prototype.setupLevel2 = function setupLevel2() {
  const obj = this;

  /* Go back to Level A */
  document.querySelector('#level2_holder .all_categories').addEventListener('click', el => {
    const the_nav = this.l1_categories;

    the_nav.querySelectorAll('.nav-open').forEach(function (l1) {
      l1.classList.remove('nav-open');
    });
    obj.initialiseLazyLoaders(the_nav);

    this.masthead_l1_holder.classList.add('expanded-nav');
    this.masthead_l1_holder.classList.remove('hide_categories');
    document.querySelector('.masthead-menu-zone2 > div:first-child').classList.remove('hide_categories');
    this.nav_container.classList.remove('expanded-nav');
    obj.meganav.classList.add('hide_categories');
    this.l1_categories.classList.add('expanded-nav');
    this.masthead_wrapper.classList.remove('sub-expanded');

    let first_choice = this.l1_categories.querySelector('li:first-child a');
    first_choice.focus();
  });

  document.querySelector('#level2_holder .fa-close').addEventListener('click', el => {
    this.closeLeft();
  });
};

megaNav.prototype.expandToggle = function expandToggle(umenu, ev) {
  // expandToggle - Indicate that the option was chosen or follow the link
  var element;
  var proceed;
  var isOpen = umenu.classList.contains('nav-open');

  if (ev) {
    element = ev.target;
    // if someone has clicked on an open category header, then follow the link
    proceed = element.classList.contains('nav-expand-href') && !isOpen;
    if (element.tagName == 'A' && !proceed) {
      nx.navigation.doAdobeCategory(element.getAttribute('data-category'));
      return;
    }

    ev.stopPropagation();
    ev.preventDefault();
  }
  this.collapseAll(umenu);

  if (!isOpen) {
    umenu.classList.add('nav-open');
    this.initialiseLazyLoaders(umenu.parentElement); // Reattach lazyExpand
  }
};

megaNav.prototype.collapseAll = function collapseAll(clickedMenu) {
  // grab the containing structure
  clickedMenu = clickedMenu.parentElement.parentElement;
  if (!clickedMenu) {
    return;
  }
  //collapse all its sections
  clickedMenu.querySelectorAll('.nav-open').forEach(function (el) {
    el.classList.remove('nav-open');
  });
};

megaNav.prototype.resize = function resize() {
  var obj = this;

  const levelC = document.querySelectorAll('#cat_heading ~ div li');
  if (levelC) {
    obj.toggleLevel3Header();

    obj.fixCategoryHeight(levelC.length);
  }
};

megaNav.prototype.toggleLevel3Header = function toggleLevel3Header() {
  const l3_head = document.querySelector('#l3_left > h2 > a:first-child');
  if (!l3_head) return; // menu has not been expanded yet
  if (window.outerWidth > 1319) {
    if (l3_head?.dataset?.url) { // was smaller
      l3_head.href = l3_head.dataset.url;
      l3_head.removeAttribute('data-url');
      l3_head.removeAttribute('tabindex');
      l3_head.setAttribute('aria-disabled', false);
    }
  } else if (!l3_head?.dataset?.url) { // was larger
    l3_head.setAttribute('data-url', l3_head.href);
    l3_head.href = '';
    l3_head.setAttribute('tabindex', -1);
    l3_head.setAttribute('aria-disabled', true);
  }
};

export default megaNav;
