/* globals nx */
import { addClassName, hasClassName, removeClassName } from '../ute/utils';

const select_cc_select = ccode => {
  const field = document.getElementById('k2c_changecc_ccode');

  document.querySelectorAll('#cost-centre-select-list .select-item').forEach(function (elt) {
    removeClassName(elt, 'selected');
    elt.querySelectorAll('i.fa').forEach(function (elt2) {
      removeClassName(elt2, 'fa-dot-circle-o');
      addClassName(elt2, 'fa-circle-o');
    });

    if (elt.getAttribute('data-ccode') == ccode) {
      addClassName(elt, 'selected');
      elt.querySelectorAll('i.fa').forEach(function (elt2) {
        removeClassName(elt2, 'fa-circle-o');
        addClassName(elt2, 'fa-dot-circle-o');
      });
    }
  });

  field.value = ccode;
}

const select_cc_filter = elt => {
  let select_cc_filter_active = false;
  const search_filter = document.getElementById('select-account-filter');

  /* 0.5s delay before running the filter, and only allow one timeout
   * at a time. */
  if (!select_cc_filter_active) {
    select_cc_filter_active = true;

    setTimeout(function () {
      let selected_count = 0;
      let last_selected = null;
      select_cc_filter_active = false; /* allow a new timeout to
                                                be set while this
                                                happens.*/
      const search_val = search_filter.value.toUpperCase();
      document.querySelectorAll('#cost-centre-select-list .select-item').forEach(function (cc_elt) {
        if (cc_elt.getAttribute('data-label').indexOf(search_val) > -1) {
          cc_elt.style.display = '';
          last_selected = cc_elt;
          selected_count++;
        } else {
          cc_elt.style.display = 'none';
        }
      });
      if (selected_count == 1) {
        var ccode = last_selected.getAttribute('data-ccode');
        select_cc_select(ccode);
      }
    }, 500);
  }
}

const select_cc_lightbox = evt => {
  if (evt) {
    evt.stopPropagation();
  }

  if (!window.nxDatalayer.global.is_b2b &&
    window.nx.ute.readDatalayer(['global', 'streams', 'auf']) &&
    !window.nx.ute.readDatalayer(['global', 'profiles', 'Anonymous']) &&
    window.nx.addToCart && window.nx.addToCart.openAccountsFrame) {
    window.nx.addToCart.openAccountsFrame();
    return;
  }

  window.Dialogs.load();

  new window.Dialog({
    title: '',
    autoOpen: true,
    width: 700,
    ajax: function () {
      const url = '/main-ajax-selectcc';
      return {
        url: url,
        options: {
          onComplete: function () {
            const search_filter = document.getElementById('select-account-filter');

            window.nx.open_lightbox.setDimensions();
            if (search_filter) {
              search_filter.addEventListener('keyup', select_cc_filter);
              search_filter.addEventListener('keydown', select_cc_filter);
              search_filter.addEventListener('keyup', select_cc_filter);
              search_filter.addEventListener('change', select_cc_filter);
            }

            document.querySelectorAll('#cost-centre-select-list .select-item').forEach(function (elt) {
              elt.addEventListener('click', function (ev) {
                let target = null;
                if (hasClassName(ev.target, 'select-item')) {
                  target = ev.target;
                }
                else {
                  target = ev.target.closest('.select-item');
                }
                const ccode = target.getAttribute('data-ccode');
                select_cc_select(ccode);
              });
            });
          }
        }
      };
    },
    afterOpen: function (d) {
      window.nx.open_lightbox = d;
    }
  });
}

export { select_cc_lightbox };
